.spinner-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    z-index: 1050; /* Make sure it covers the modal content */
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}